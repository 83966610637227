import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useMedia } from "use-media";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const image1 = require("../../../assets/images/blog_13/b13_1.webp");
const image2 = require("../../../assets/images/blog_13/b13_2.jpg");
const image3 = require("../../../assets/images/blog_13/b13_3.jpg");
const image4 = require("../../../assets/images/blog_13/b13_4.jpg");
const image5 = require("../../../assets/images/blog_13/b13_5.jpg");
const image6 = require("../../../assets/images/blog_13/b13_6.jpg");
const image7 = require("../../../assets/images/blog_13/b13_8.webp");

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Intergate Okta with Chatbot to automate unlock account, password reset and access management tasks"
        description="Learn how Okta customers are automating repetitive access management tasks using workativ assistant chatbot and streamline various employees IT requests."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-container">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Deliver IT Process Automation on Unlock Account,
                            Password Reset, and Access Management via Okta
                            Workflow Automation
                          </h1>
                          <img src={image1} className="mb-4"></img>
                        </div>
                      </div>
                      <div className=" market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Is your business using{" "}
                          <a href="https://workativ.com/conversational-ai-platform/okta-chatbot">
                            Okta for Identity and Access Management?
                          </a>{" "}
                          Then you might know how manually provisioning and
                          deprovisioning users can be cumbersome for your IT
                          team. Every new hire or company exit requires timely
                          modifications to a user’s account. As a result, IT
                          spend inordinate amounts of time on these processes
                          and are distracted from other priorities, and your
                          end-users suffer delays in productivity if their
                          accounts aren’t configured correctly. But you can
                          change all that with{" "}
                          <a href="https://workativ.com/conversational-ai-platform">
                            Workativ Assistant.
                          </a>
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant is a no-code platform for building
                          contextual chatbots with automated workflows for
                          business apps. You can easily create and add Workativ
                          Assistant’s chatbots to the chat channel of your
                          choice like{" "}
                          <a href="https://blog.workativ.com/transform-your-business-slack-channel-into-an-it-help-desk-for-your-employees-using-workativ-assistant/">
                            Slack
                          </a>{" "}
                          or{" "}
                          <a href="https://blog.workativ.com/microsoft-teams-vs-slack/">
                            Microsoft Teams.&nbsp;
                          </a>
                          . Once you add a Workativ Assistant chatbot to your
                          Slack/Microsoft Teams workspace, your employees can
                          make use of the chatbot to self-serve their IT issues,
                          on-the-go, 24×7, on their laptop or mobile device.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, you take the first step
                          towards easing the burden on your users. By automating
                          <a href="https://workativ.com/use-cases/user-provisioning-automation">
                            &nbsp;user provisioning
                          </a>{" "}
                          and deprovisioning on Okta, you see{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                            tangible benefits
                          </a>
                          like saving your organization tons of money by
                          utilizing help desk agents for mission-critical tasks
                          that move your organization forward. Automating Okta
                          processes also ensures that new employees can start
                          working on Day 1.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          By connecting your Okta account with Workativ
                          Assistant, you can automate:
                        </p>
                      </div>
                      <div>
                        <h5 class="font-section-sub-header-small">
                          1. Access Management
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Automate Access Management tasks like:
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Add a user —
                          </span>
                          Manually adding details about an employee whenever
                          there’s a new hire can be frustrating for a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/virtual-agent-powered-it-helpdesk">
                            help desk agent
                          </a>{" "}
                          and new employees as well. Get rid of this for them
                          with Workativ Assistant’s Okta chatbot that let’s help
                          desk agents add details about a new employee on Okta
                          by providing information about the employee
                          conversationally.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Remove a user —
                          </span>
                          <a href="https://workativ.com/use-cases/employee-offboarding-automation">
                            Offboarding an employee
                          </a>{" "}
                          shouldn’t be a hassle on your help desk agents.
                          Instead of your help desk agents manually searching
                          for the employee’s details and removing them, you can
                          automate the entire process conversationally with
                          Workativ Assistant’s Okta chatbot so that help desk
                          agents can remove an outgoing employee in just
                          seconds.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Add a user to a group —
                          </span>
                          With Workativ Assistant’s chatbot, you ensure that
                          help desk agents can add new hires to the company’s
                          email list effortlessly by telling the chatbot which
                          employee to add.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Add a group —
                          </span>
                          With Workativ Assistant’s chatbot, help desk agents
                          can create email lists like a breeze by having a quick
                          conversation with the chatbot.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Retrieve details about a user —
                          </span>
                          Help desk agents can check details about an employee
                          when they are trying to solve an IT ticket raised by
                          that employee just by asking Workativ Assistant’s
                          chatbot on their Slack or Microsoft Teams app.
                        </p>
                        <p className="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Suspend a user —
                          </span>
                          In case help desk agents come across any security
                          compromising activity on an employee’s network, they
                          can suspend the employee’s account before
                          investigating the situation to prevent a possible
                          hack.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          2. Account Unlock
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Unsuspend a user —
                          </span>
                          Whenever an employee finds themselves locked out of
                          their desktop/laptop, they can unlock their account
                          themselves on{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-channels">
                            Slack or Microsoft Teams
                          </a>{" "}
                          via Workativ Assistant’s Okta AI chatbot on their
                          mobile phone.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Not only can you set up these Okta workflow
                          automations, but you can also combine these with{" "}
                          <a href="https://workativ.com/assistant/workflow-automation">
                            workflow automations for your existing business apps
                          </a>{" "}
                          with Workativ Assistant Power-Ups.
                        </p>

                        <h5 class="font-section-sub-header-small">
                          Workativ Assistant Power-Ups for your existing
                          business applications
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/assistant/marketplace">
                            Workativ Assistant Power-Ups
                          </a>{" "}
                          are a set of steps that Workativ Assistant’s chatbots
                          will follow to get work done between your apps.
                          Power-ups have many advanced features, allowing them
                          to handle all app integration and workflow automation
                          scenarios, including complex data transformations,
                          conditional triggers and actions, and much more.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          You can get your first power-up running in less than 5
                          minutes which involves:
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Connecting to Apps:
                          </span>
                          Cloud-based or on-premise apps that record various
                          business events e.g. Jira
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Setting a Trigger:
                          </span>
                          Business events that will kick off the integration to
                          complete actions automatically e.g. an employee asking
                          a chatbot to unlock their account
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Automate Actions:
                          </span>
                          Set of business event outcomes that you want to
                          accomplish based on the triggered event e.g. unlocking
                          an account on Okta and informing the user via Vonage
                          text message that their account has been unlocked.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <span className="font-section-normal-text-medium">
                            Test and Start:
                          </span>
                          Test the connections, check triggers, validate
                          actions.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Sounds simple, right? Let’s take a look at some of the
                          power-ups that you can do with Workativ Assistant.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Okta Integration with Office 365
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          The thought of creating an email ID for new hires
                          manually on Office 365 and then adding the hire to the
                          company’s email list can be exhausting for a help desk
                          agent when they can focus on solving complex IT issues
                          at hand. Not just your help desk agents, your new
                          employees won’t have a good initial impression of your
                          organization if their{" "}
                          <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                            onboarding
                          </a>{" "}
                          doesn’t go smoothly as expected. So what can you do
                          about this?
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, you can set up an automation
                          by connecting your Office 365 and Okta accounts such
                          that, after retrieving details about a user from Okta,
                          the details are used to create an email ID for a new
                          user on Office 365 and then the email ID gets added
                          automatically to the company’s email list.
                        </p>
                        <img loading="lazy" src={image2} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Jira Okta Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          New developer joining your company? No worries. You
                          can set up a Workativ Assistant automation by
                          <a href="https://workativ.com/integration/jira+okta">
                            &nbsp;connecting your Jira and Okta
                          </a>{" "}
                          accounts such that, after retrieving details about the
                          developer from Okta, the details are used to create a
                          Jira account for the developer and add them to your
                          company’s Jira workspace.
                        </p>
                        <img loading="lazy" src={image3} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          3. Okta Slack Integration
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Whenever there’s a new hire, help desk employees have
                          to create an email ID for the hire and convey the same
                          to the hire. This can be a bit frustrating for help
                          desk agents when the hiring pool is large and
                          frequent.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          By connecting your business’ Okta and Slack accounts,
                          you can set up an automation such that, after creating
                          an email ID for a new hire, the same gets conveyed to
                          the hire on their Slack by Workativ Assistant’s
                          chatbot.
                        </p>
                        <img loading="lazy" src={image4} className="mb-5"></img>

                        <h5 class="font-section-sub-header-small">
                          How you can effortlessly create an Azure AD chatbot
                          with Workativ Assistant
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          With Workativ Assistant, you can create a chatbot for{" "}
                          <a href="https://workativ.com/assistant/it-process-automation-with-ai-chatbot">
                            IT process automation
                          </a>{" "}
                          in just a few minutes. Let’s see how.
                        </p>
                        <h5 class="font-section-sub-header-small">
                          1. Workflow builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Workativ assistant comes with easy-to-use no-code
                          workflow builder to help you build simple to complex
                          app-based automations for chatbot in minutes.
                          Integrate chatbot with apps, use pre-built workflows
                          from marketplace, approve requests, connect chatbot
                          with on-prem apps and much more.
                        </p>

                        <img loading="lazy" src={image5} className="mb-5"></img>
                        <h5 class="font-section-sub-header-small">
                          2. Chatbot Builder
                        </h5>
                        <p class="font-section-normal-text line-height-2">
                          Our{" "}
                          <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                            easy-to-use no-code chatbot builder
                          </a>{" "}
                          helps you build and manage simple to complex
                          conversations, FAQs, integrate app workflows, and
                          personalize your bot in minutes. Deliver autonomous
                          workplace support with purpose built intelligent
                          chatbots.
                        </p>
                        <img loading="lazy" src={image6} className="mb-5"></img>
                        <img loading="lazy" src={image7} className="mb-5"></img>
                      </div>

                      <NocodeWrapper />
                      <h6 className="font-section-sub-header-small-bold">
                        What all these mean for you
                      </h6>

                      <div className="market_wrapper_page ">
                        <h6 className="font-section-sub-header-small ">
                          1. Intuitive conversational AI for an awesome employee
                          experience
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          No one prefers boring manual data entry portals even
                          if it’s Okta. With Workativ Assistant’s chatbots,
                          provide the{" "}
                          <a href="https://workativ.com/chatbot/ai-self-service-chatbot">
                            seamless conversational self-service
                          </a>{" "}
                          that your employees would appreciate.
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          2. All from the comfort of your chat hub
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant’s Okta chatbot proactively resides
                          on your Slack/Microsoft Teams workspace waiting to
                          resolve your employees’ IT queries like okta
                          self-service password reset or account unlock in an
                          instant,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-employee-support-24-7">
                            24×7.
                          </a>
                        </p>

                        <h6 className="font-section-sub-header-small ">
                          3. Faster business processes
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          No more logging in and out of different applications
                          and switching between them endlessly. With Workativ
                          Assistant, you make it easier on your employees by
                          clubbing existing business apps together and providing
                          their services through your business’ Slack or
                          Microsoft Teams workspace. Employees will then just
                          have to open their Slack or Microsoft Teams mobile/web
                          app to do what they want by having a chat with
                          Workativ Assistant’s chatbot there, on-the-go, 24×7.
                        </p>

                        <h5 className="font-section-sub-header-small">
                          4. Improved IT support experience for employees
                        </h5>

                        <p class="font-section-normal-text line-height-2">
                          No more repetitive calls/tickets to help desk. With
                          Workativ assistant’s chatbot, you make sure your
                          employees don’t have to wait on endless calls to get
                          what they’re looking for which in turn leads to your
                          help desk agents’ productivity increase, letting them
                          concentrate on the complex tasks at hand that requires
                          a human touch.
                        </p>

                        <p class="font-section-normal-text-medium line-height-2">
                          So what’s stopping you? Get your hands on Workativ
                          Assistant today by signing up for a FREE trial at
                          <a href="https://workativ.com/">&nbsp;workativ.com</a>
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot-for-employee-service">
                                Conversational AI Chatbot for employee service
                                automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/top-tools-to-build-conversational-ai-chatbot">
                                {" "}
                                Conversational AI Tools—Top 6 Tools To Build
                                Conversational AI Chatbot
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai">
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 
            {isSmall ? null : <OnScrollPopup />} */}
            {/* 
            <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Okta Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="okta-chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
